<template>
    <div class="staking-history-table">
        <div v-if="isUserLoad" class="container">
            <div class="title">
                {{ $i18n.get(`History`) }}
            </div>

            <div class="table" v-if="!$store.state.isMobile">
                <div class="table-string -head">
                    <div class="date">
                        {{ $i18n.get(`Opening date`) }}
                    </div>

                    <div class="date">
                        {{ $i18n.get(`End date`) }}
                    </div>

                    <div class="period">
                        {{ $i18n.get(`Selected period`) }}
                    </div>

                    <div class="amount" v-if="type === 'staking'">
                        {{ $i18n.get(`Amount of tokens staked`) }}
                    </div>

                    <div class="amount" v-if="type === 'swap'">
                        {{ $i18n.get(`Swapped → Received`) }}
                    </div>

                    <div class="earned">
                        {{ $i18n.get(`Earned`) }}
                    </div>

                    <div class="apr">
                        {{ $i18n.get(`APR`) }}
                    </div>
                </div>

                <div class="table-string" v-for="(staking, index) of historyStaks" :key="index">
                    <div class="date">
                        {{ $moment.unix(staking.start).utcOffset(user.timeZone).format('DD.MM.YYYY') }}
                    </div>

                    <div class="date">
                        {{ $moment.unix(staking.end).utcOffset(user.timeZone).format('DD.MM.YYYY') }}
                    </div>

                    <div class="period">
                        {{ staking.period }} {{ $i18n.get(`days`) }}
                    </div>

                    <div class="amount" v-if="type === 'staking'">
                        {{ $functions.getClearMoney(staking.amount) }} TFT
                    </div>

                    <div class="amount" v-if="type === 'swap'">
                        {{ $functions.getClearMoney(staking.amount / 5) }} TFL
                        →
                        {{ $functions.getClearMoney(staking.amount) }} TFT
                    </div>

                    <div class="earned -green">
                        {{ $functions.getClearMoney(staking.earned) }} TFT
                    </div>

                    <div class="apr">
                        {{ staking.apr }}%
                    </div>
                </div>
            </div>

            <template v-if="$store.state.isMobile">
                <div class="mobile-history-item" v-for="(staking, index) of historyStaks" :key="index">
                    <div class="str">
                        {{ $i18n.get(`Opening date`) }}

                        <span>{{ $moment.unix(staking.start).utcOffset(user.timeZone).format('DD.MM.YYYY') }}</span>
                    </div>

                    <div class="str">
                        {{ $i18n.get(`End date`) }}

                        <span>{{ $moment.unix(staking.end).utcOffset(user.timeZone).format('DD.MM.YYYY') }}</span>
                    </div>

                    <div class="str">
                        {{ $i18n.get(`Selected period`) }}

                        <span>{{ staking.period }} {{ $i18n.get(`days`) }}</span>
                    </div>

                    <div class="str" v-if="type === 'staking'">
                        {{ $i18n.get(`Amount of tokens staked`) }}

                        <span>{{ $functions.getClearMoney(staking.amount) }} TFT</span>
                    </div>

                    <div class="str" v-if="type === 'swap'">
                        {{ $i18n.get(`Swapped → Received`) }}

                        <span>
                            {{ $functions.getClearMoney(staking.amount / 5) }} TFL
                            →
                            {{ $functions.getClearMoney(staking.amount) }} TFT
                        </span>
                    </div>

                    <div class="str">
                        {{ $i18n.get(`Earned`) }}

                        <span class="-green">
                            {{ $functions.getClearMoney(staking.earned) }} TFT
                        </span>
                    </div>

                    <div class="str">
                        {{ $i18n.get(`APR`) }}

                        <span>
                            {{ staking.apr }}%
                        </span>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'history-table',
    props: {
        historyStaks: {
            type: Array,
            default() {
                return []
            }
        },
        type: {
            default: 'staking'
        }
    },
    data() {
        return {
            history: [
                {
                    open: new Date,
                    end: new Date,
                    period: 360,
                    amount: {
                        value: 5000,
                        currencyCode: 'TFT'
                    },
                    earned: 86400,
                    apr: 842
                },
                {
                    open: new Date,
                    end: new Date,
                    period: 180,
                    amount: {
                        value: 232,
                        currencyCode: 'TFL'
                    },
                    earned: 123123,
                    apr: 12341
                },
            ]
        }
    },
    computed: {
        ...mapGetters('user', {
            isUserLoad: 'isLoad',
            user: 'get'
        })
    }
}
</script>

<style lang="less">
.staking-history-table {
    background-color: var(--main-grey);
    padding-bottom: 50px;
    padding-top: 50px;

    > .container {
        width: var(--main-width-full);
        margin: 0 auto;

        > .title {
            font-weight: bold;
            font-size: 32px;
            line-height: 48px;
        }

        > .table {
            margin-top: 20px;

            > .table-string {
                display: flex;
                justify-content: space-between;
                column-gap: 50px;
                padding: 20px 50px;
                background-color: var(--white);
                border-bottom: 1px solid var(--dark-blue-10);

                > div {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;

                    &:first-child {
                        width: 95px;
                    }

                    &:nth-child(2) {
                        width: 85px;
                    }

                    &:nth-child(3) {
                        width: 105px;
                    }

                    &:nth-child(4) {
                        text-align: end;
                        width: 182px;
                    }

                    &:nth-child(5) {
                        text-align: end;
                        width: 140px;
                    }

                    &:last-child {
                        text-align: end;
                        width: 80px;
                    }
                }

                &.-head {
                    color: var(--dark-blue-40);
                    border-radius: 20px 20px 0px 0px;
                    padding: 15px 50px;
                    background: #fbfcfd;

                    > div {
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 19px;
                    }
                }

                &:last-child {
                    border-radius: 0px 0px 20px 20px;
                    border-bottom: none;
                }

                > .earned {
                    &.-green {
                        color: var(--green);
                    }
                }
            }
        }
    }
}

@media (max-width: 1279px) {
    .staking-history-table {
        > .container {
            > .table {
                > .table-string {
                    display: flex;
                    justify-content: space-between;
                    column-gap: 25px;
                    padding: 20px 40px;
                    background-color: var(--white);
                    border-bottom: 1px solid var(--dark-blue-10);

                    > div {
                        font-size: 14px;
                        line-height: 21px;
                    }

                    &.-head {
                        padding: 15px 40px;

                        > div {
                            font-size: 12px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .staking-history-table {
        > .container {
            > .title {
                font-size: 24px;
                line-height: 36px;
            }

            > .table {

                > .table-string {
                    column-gap: 0px;
                    padding: 20px 20px;

                    > div {
                        font-size: 14px;
                        line-height: 21px;

                        &:first-child {
                            width: 85px;
                        }

                        &:nth-child(2) {
                            width: 71px;
                        }

                        &:nth-child(3) {
                            width: 95px;
                        }

                        &:nth-child(4) {
                            text-align: end;
                            width: 150px;
                        }

                        &:nth-child(5) {
                            text-align: end;
                            width: 76px;
                        }

                        &:last-child {
                            text-align: end;
                            width: 80px;
                        }
                    }

                    &.-head {
                        padding: 15px 20px;
                        font-size: 12px;
                        line-height: 18px;

                        > div {
                            font-size: 12px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .staking-history-table {
        padding: 0px 15px 40px;

        > .container {
            > .title {
                font-size: 20px;
                line-height: 26px;
                text-align: center;
            }

            .mobile-history-item {
                margin-top: 10px;

                > .str {
                    padding: 10px 15px;
                    border-bottom: 1px solid var(--dark-blue-10);
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 18px;
                    background-color: var(--white);
                    display: flex;
                    justify-content: space-between;
                    color: var(--dark-blue-40);

                    > span {
                        color: var(--dark-blue);
                        text-align: end;

                        &.-green {
                            color: var(--green);
                        }
                    }

                    &:first-child {
                        border-radius: 20px 20px 0px 0px;
                    }

                    &:last-child {
                        border-radius: 0px 0px 20px 20px;
                    }
                }
            }
        }
    }
}
</style>
