<template>
    <div class="stacking-input">
        <div class="container">
            <button @click="decrement">
                <svg width="18" height="2" viewBox="0 0 18 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 0V1.56H0V0H18Z" fill="#001A54"/>
                </svg>
            </button>

            <label class="input-container">
                <input v-model="value" @blur="blurInput">

                <div class="currency">
                    {{ currency }}
                </div>
            </label>

            <button @click="increment">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 10.7451H10.8235V20H9.13725V10.7451H0V9.2549H9.13725V0H10.8235V9.2549H20V10.7451Z" fill="#001A54"/>
                </svg>
            </button>
        </div>

        <transition name="auth">
            <div class="warning-tooltip" v-if="valid">
                <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.0643 1.36364C7.14683 -0.454546 9.85317 -0.454545 10.9357 1.36364L16.619 10.9091C17.7015 12.7273 16.3484 15 14.1833 15H2.8167C0.651632 15 -0.701533 12.7273 0.381 10.9091L6.0643 1.36364Z" fill="#F66514"/>
                    <path d="M9.304 4L9.064 9.748H7.324L7.084 4H9.304ZM8.224 12.82C7.864 12.82 7.568 12.716 7.336 12.508C7.112 12.292 7 12.028 7 11.716C7 11.396 7.112 11.128 7.336 10.912C7.568 10.696 7.864 10.588 8.224 10.588C8.576 10.588 8.864 10.696 9.088 10.912C9.32 11.128 9.436 11.396 9.436 11.716C9.436 12.028 9.32 12.292 9.088 12.508C8.864 12.716 8.576 12.82 8.224 12.82Z" fill="white"/>
                </svg>

                {{ $i18n.get(`Not enough funds`) }}

                <w-button type="blue" :to="{name: 'assets'}" :size="$store.state.isMobile ? 'xs' : 'middle'">
                    {{ $i18n.get(`Add funds`) }}
                </w-button>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'stacking-input',
    props: {
        field: {
            type: Number,
            default: 0
        },
        currency: {
            default: ''
        },
        valid: {
            type: Boolean,
            default: false
        }
    },
    model: {
        prop: 'field',
        event: 'input'
    },
    data() {
      return {
          value: this.field,
          valuePrecision: 6,
          inputRules: [
              v => {
                  const regExp = new RegExp('^[0-9]*[.]?[0-9]{0,' + this.valuePrecision + '}$')
                  return regExp.test(v)
              },
              v => !isNaN(v)
          ],
      }
    },
    watch: {
        value(newVal, oldVal) {
            if (newVal === '') {
                return this.value = 0
            }

            let value = parseFloat(newVal);

            for (let i = 0; i < this.inputRules.length; i++) {
                if (!this.inputRules[i](newVal)) {
                    return this.value  = oldVal
                }
            }

            this.$emit('input', value)
        },
        field(val) {
            this.value = val
        }
    },
    methods: {
        increment() {
            this.value = (parseFloat(this.value) + 1).toFixed(this.valuePrecision)
        },
        decrement() {
            if (this.value > 1) {
                this.value = (parseFloat(this.value) - 1).toFixed(this.valuePrecision)
            }
        },
        blurInput() {
            if (this.value == 0) {
                this.value = 1
            }
        }
    }
}
</script>

<style lang="less">
.stacking-input {
    display: inline-flex;
    column-gap: 10px;

    .container {
        display: inline-flex;
        border: 1px solid var(--dark-blue-20);
        border-radius: 10px;
        align-items: center;

        > button {
            display: block;
            width: 70px;
            height: 70px;
            border: none;
            background: none;
            font-weight: 100;
            font-size: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:first-child {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    top: 15px;
                    bottom: 15px;
                    width: 1px;
                    right: 0px;
                    background: #CCD1DD;
                }
            }

            &:last-child {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    top: 15px;
                    bottom: 15px;
                    width: 1px;
                    left: 0px;
                    background: #CCD1DD;
                }
            }
        }

        > .input-container {
            height: 100%;
            min-width: 340px;
            max-width: 900px;
            overflow: hidden;
            font-weight: 500;
            font-size: 28px;
            line-height: 144%;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 4px;

            > input {
                font-weight: 500;
                font-size: 28px;
                line-height: 144%;
                width: 100%;
                text-align: end;
                color: var(--dark-blue);
                border: none;
                color: var(--dark-blue);
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }
            }

            > .currency {
                width: 50%;
                flex-basis: 100%;
                flex-grow: 1;
                // flex-shrink: 0;
            }
        }
    }

    > .warning-tooltip {
        background: #FEF0E8;
        border-radius: 10px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: var(--red);
        column-gap: 12px;
    }

    @keyframes cursor {
        0% {
            border-color: transparent;
        }

        50% {
            border-color: var(--dark-blue);
        }

        100% {
            border-color: transparent;
        }
    }
}

@media (max-width: 1023px) {
    .stacking-input {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        column-gap: 10px;

        .container {
            display: flex;
            border: 1px solid var(--dark-blue-20);
            border-radius: 10px;
            align-items: center;
            justify-content: space-between;

            > .input-container {
                height: 100%;
                min-width: 340px;
                max-width: 900px;
                overflow: hidden;
                font-weight: 500;
                font-size: 28px;
                line-height: 144%;
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 4px;

                > input {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 144%;
                    width: 100%;
                    text-align: end;
                    color: var(--dark-blue);
                    border: none;
                    color: var(--dark-blue);
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                    }
                }

                > .currency {
                    width: 50%;
                    flex-basis: 100%;
                    flex-grow: 1;
                }
            }
        }

        > .warning-tooltip {
            justify-content: center;
        }
    }
}

@media (max-width: 767px) {
    .stacking-input {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        column-gap: 10px;

        .container {
            height: 60px;

            > button {
                width: 60px;
                height: 60px;
            }

            > .input-container {
                height: 100%;
                font-weight: 500;
                font-size: 16px;
                line-height: 144%;
                min-width: auto;
                max-width: auto;
                overflow: hidden;

                > input {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 144%;
                }
            }
        }

        > .warning-tooltip {
            justify-content: center;
            font-weight: 500;
            font-size: 11px;
            line-height: 16px;
        }
    }
}
</style>
