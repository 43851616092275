<template>
    <div class="stacking-page">
        <div class="stacking-offer-block">
            <div class="container">
                <h1 class="title"
                    v-html="$i18n.get(`Stake your TFT <br> and get rewards`)">
                </h1>

                <div class="description">
                    {{ $i18n.get(`You can stake your funds and receive a reward every day. Available staking period depends on your status (30-360 days). The rate is adjustable and depends on the staking period, your status, the amount, and the total amount of the staked funds. This function is available to users with Bronze status and above.`) }}
                </div>

                <div v-if="isGuest || userRole === 'member'" class="warning-block">
                    <template v-if="isGuest">
                        <div class="guest">
                            APR: {{ apr }}%
                            <span>{{ $i18n.get(`at the moment`) }}</span>
                        </div>

                        <w-button @click="login()" type="orange">
                            {{ $i18n.get(`Log in to proceed`) }}
                        </w-button>
                    </template>

                    <template v-else>
                        <template>
                            {{ $i18n.get(`You need to buy status-card to receive staking rewards`) }}
                        </template>

                        <w-button :to="{name: 'market'}" type="blue">
                            {{ $i18n.get(`Buy Status`) }}
                        </w-button>
                    </template>
                </div>
            </div>
        </div>

        <div class="content" :class="{'-padding' : isGuest || userRole === 'member'}">
            <active-staking v-if="!isGuest && userRole !== 'member' && active.length"
                @unstake="load()"
                :active-staks="active">
            </active-staking>

            <info-tables v-if="!isLoading" :rates="rates"></info-tables>

            <div class="status-info-banner" v-if="isGuest || userRole === 'member'">
                <template v-if="isGuest">
                    <div class="text -guest">
                        APR: {{ apr }}%
                        <span>{{ $i18n.get(`at the moment`) }}</span>
                    </div>

                    <w-button @click="login()" type="orange" :size="$store.state.isMobile ? 'small': 'large'">
                        {{ $i18n.get(`Log in to proceed`) }}
                    </w-button>
                </template>

                <template v-else>
                    <div class="text"
                        v-html="$i18n.get(`You need to buy status-card <br> to receive staking rewards`)">
                    </div>

                    <w-button type="blue" :to="{name: 'market'}" :size="$store.state.isMobile ? 'small': 'large'">
                        {{ $i18n.get(`Buy Status`) }}
                    </w-button>
                </template>
            </div>
        </div>

        <div class="stacking-table-container" v-if="!isGuest && userRole !== 'member'">
            <div class="container">
                <div class="title">
                    {{ $i18n.get(`Staking`) }}
                </div>

                <reward-table
                    @confirm-staking="confirmStaking"
                    @confirm-add-staking="confirmAddStaking"
                    :active="active"
                    :disabled="isValidAmount || this.stakeAmount <= 0"
                    :amount="stakeAmount"
                    :rates="rates"
                    :info="info">

                    <template #default>
                        <staking-input v-model="stakeAmount"
                            currency="TFT"
                            :valid="isValidAmount">
                        </staking-input>
                    </template>
                </reward-table>
            </div>
        </div>

        <history-table v-if="!isGuest && userRole !== 'member' && history.length"
            :history-staks="history"
            type="staking">
        </history-table>
    </div>
</template>

<script>
import InfoTables from './snippets/InfoTables.vue'
import RewardTable from './snippets/RewardTable.vue'
import StakingInput from './snippets/StakingInput.vue'
import HistoryTable from './snippets/HistoryTable.vue'
import ActiveStaking from './snippets/ActiveStaking.vue'

export default {
    name: 'stacking',
    components: {
        'info-tables': InfoTables,
        'reward-table': RewardTable,
        'staking-input': StakingInput,
        'history-table': HistoryTable,
        'active-staking': ActiveStaking
    },
    data() {
        return {
            isLoading: true,
            stakeAmount: 1,
            apr: 0,
            rates: {},
            info: {},
            active: [],
            history: []
        }
    },
    watch: {
        isGuest() {
            if (!this.isGuest) {
                this.load()

                this.stakeAmount = this.$store.state.user.balance
            }
        },
        userBalance() {
            this.stakeAmount = this.userBalance
        }
    },
    computed: {
        userBalance() {
            return this.$store.state.user.balance
        },
        isGuest() {
            return this.$store.state.isGuest
        },
        userRole() {
            if (this.isGuest) {
                return false
            }

            return this.$store.state.user.status.role
        },
        isValidAmount() {
            if (this.isGuest) {
                return false
            }

            return this.$store.state.user.balance < this.stakeAmount
        }
    },
    methods: {
        confirmStaking(params) {
            this.$modal.show(() => import(/* webpackChunkName: 'staking' */ './modals/ConfirmStaking.vue'), Object.assign(params, {
                method: 'staking',
                callback: this.load
            }), {
                name: 'confirm-staking',
                width: 450
            })
        },
        confirmAddStaking(params) {
            this.$modal.show(() => import(/* webpackChunkName: 'staking' */ './modals/ConfirmAddStaking.vue'), Object.assign(params, {
                method: 'staking',
                callback: this.load
            }), {
                name: 'confirm-staking',
                width: 450
            })
        },
        login() {
            document.location.hash = '#/login'
        },
        load() {
            this.$request.get('/earn/staking', {}, response => {
                this.isLoading = false

                this.apr = response.staking.apr
                this.rates = response.staking.rates
                this.info = response.staking.info
                this.active = response.staking.active
                this.history = response.staking.history
            })
        }
    },
    created() {
        this.load()

        if (!this.isGuest) {
            this.stakeAmount = this.$store.state.user.balance
        }
    }
}
</script>

<style lang="less">
.stacking-page {
    padding-bottom: 40px;
    background-color: var(--main-grey);

    > .stacking-offer-block {
        min-height: 530px;
        color: var(--white);
        background-color: var(--dark-blue);
        background-image: url(./images/stacking-offer-bg.webp);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 50px;

        > .container {
            width: var(--main-width-full);
            margin: 0 auto;

            > .title {
                font-weight: 700;
                font-size: 46px;
                line-height: 50px;
                margin: 0;
            }

            > .description {
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                opacity: 0.8;
                margin-top: 20px;
                max-width: 530px;
            }

            > .warning-block {
                max-width: 490px;
                margin-top: 30px;
                background: var(--white-10);
                backdrop-filter: blur(40px);
                padding: 30px;
                border-radius: 20px;
                border: 1px solid var(--white-10);
                display: inline-flex;
                justify-content: space-between;
                column-gap: 15px;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                align-items: center;

                > .btn {
                    flex-shrink: 0;
                }

                > .guest {
                    display: flex;
                    flex-direction: column;

                    > span {
                        opacity: 0.6;
                        font-size: 18px;
                        line-height: 27px;
                    }
                }
            }
        }
    }

    > .content {
        background-color: var(--main-grey);
        padding: 80px 0px 0px;

        > .status-info-banner {
            margin-top: 40px;
            width: var(--main-width-full);
            margin: 40px auto 0px;
            background-image: url(./images/status-info-banner-bg.webp);
            background-size: 105% 105%;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 20px;
            padding: 40px;
            display: flex;
            justify-content: center;
            align-items: center;


            > .text {
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                color: var(--white);
                margin-right: 20px;

                &.-guest {
                    display: flex;
                    flex-direction: column;

                    > span {
                        font-size: 18px;
                        line-height: 27px;
                        opacity: 0.6;
                    }
                }
            }
        }

        &.-padding {
            padding-bottom: 80px;
        }
    }

    > .stacking-table-container {
        background-color: var(--main-grey);

        > .container {
            width: var(--main-width-full);
            margin: 0 auto;
            padding: 40px 0px 40px;

            > .title {
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
            }
        }
    }
}

@media (max-width: 1279px) {
    .stacking-page {
        > .stacking-offer-block {
            > .container {
                > .description {
                    max-width: 430px;
                }

                > .warning-block {
                    max-width: 430px;
                }
            }
        }
        > .stacking-table-container {
            > .container {
                > .title {
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 48px;
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .stacking-page {
        > .stacking-offer-block {
            background-position: center;
            background-size: cover;
            > .container {
                > .title {
                    font-size: 36px;
                    line-height: 44px;
                }

                > .description {
                    max-width: 350px;
                    font-size: 14px;
                    line-height: 25px;
                }

                > .warning-block {
                    font-size: 15px;
                    line-height: 22px;
                    width: 350px;
                    column-gap: 5px;
                    padding: 15px 20px;

                    > .btn {
                        font-size: 13px;
                        line-height: 19px;
                        padding: 10px 20px;
                    }

                    > .guest {
                        > span {
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
        > .stacking-table-container {
            > .container {
                > .title {
                    font-size: 24px;
                    line-height: 36px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .stacking-page {
        padding-bottom: 15px;

        > .stacking-offer-block {
            min-height: auto;
            background-position: -480px center;
            background-size: 2560px 100%;
            padding: 15px 15px 30px 15px;

            > .container {
                > .title {
                    font-size: 24px;
                    line-height: 32px;
                    text-align: center;
                }

                > .description {
                    margin-top: 10px;
                    max-width: 100%;
                    font-size: 12px;
                    line-height: 20px;
                    text-align: center;
                }

                > .warning-block {
                    max-width: 100%;
                    width: 100%;
                    margin-top: 20px;
                    flex-direction: column;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    row-gap: 10px;
                    padding: 20px;

                    > .btn {
                        width: 100%;
                    }

                    > .guest {
                        flex-direction: row;
                        column-gap: 5px;
                        align-items: center;

                        > span {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }

        > .content {
            background-color: var(--main-grey);
            padding: 15px;

            > .status-info-banner {
                margin-top: 15px;
                flex-direction: column;
                padding: 15px;

                > .text {
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;

                    &.-guest {
                        display: flex;
                        flex-direction: column;

                        > span {
                            font-size: 18px;
                            line-height: 27px;
                            opacity: 0.6;
                        }
                    }
                }

                > .btn {
                    margin-top: 10px;
                }
            }
        }
        > .stacking-table-container {
            > .container {
                padding: 0px 0px 15px 0px;
                > .title {
                    font-size: 20px;
                    line-height: 26px;
                    text-align: center;
                }
            }
        }
    }
}
</style>
