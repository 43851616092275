<template>
    <div class="stacking-swap-info-tables" v-if="isDesktop">
        <div class="table-item">
            <div class="title">
                {{ $i18n.get(`Available periods`) }}
            </div>

            <div class="table">
                <div class="text">
                    {{ $i18n.get(`The rate is adjustable and depends on the staking period, your status, the amount, and the total amount of the staked funds.`) }}
                </div>

                <div class="container">
                    <div class="column -name">
                        <div class="cell" v-for="(type, index) of types" :key="index">
                            {{ $functions.firstSymbolToUpperCase(type) }}
                        </div>
                    </div>

                    <div class="column" v-for="(group, key) of convertRates" :key="key">
                        <div class="cell -name" :key="key + ' name'" v-html="$i18n.get(`{period, plural, one {# day} other {# days}}`, {period: group.period})"></div>

                        <div class="cell" v-for="(coefficient, key) of group.data" :key="key">
                            <svg v-if="coefficient" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10" r="10" fill="#77DA29"/>
                                <path d="M6.875 10.625L8.75 12.5L13.75 7.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            
                            <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10" r="10" fill="#001A54" fill-opacity="0.2"/>
                                <rect x="6" y="9" width="8" height="2" rx="1" fill="white"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-item">
            <div class="title">
                {{ $i18n.get(`Reward ratios`) }}
            </div>

            <div class="table">
                <div class="text"
                    v-html="$i18n.get(`The ratios in the table below are multipliers for the reward amount (<span>R</span>). Your reward depends on the staking period and your status. `)">
                </div>
                
                <div class="container">
                    <div class="column -name">
                        <div class="cell" v-for="(type, index) of types" :key="index">
                            {{ $functions.firstSymbolToUpperCase(type) }}
                        </div>
                    </div>

                    <div class="column" v-for="(group, key) of convertRates" :key="key">
                        <div class="cell -name" :key="key + ' name'" v-html="$i18n.get(`{period, plural, one {# day} other {# days}}`, {period: group.period})"></div>

                        <div class="cell" v-for="(coefficient, key) of group.data" :key="key">
                            <div v-if="coefficient" class="coefficient">
                                x{{ coefficient }}
                            </div>
                            
                            <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10" r="10" fill="#001A54" fill-opacity="0.2"/>
                                <rect x="6" y="9" width="8" height="2" rx="1" fill="white"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="table-swiper-tablet" v-else-if="isTablet">
        <div class="swiper-header" ref="header">
            <div @click="swipeTo(0, 'swiper-tablet')" class="item" :class="{'-active': selectedSlide === 0}">
                <div class="bold"> {{ $i18n.get(`Available periods`) }}</div>
            </div>

            <div @click="swipeTo(1, 'swiper-tablet')" class="item" :class="{'-active': selectedSlide === 1}">
                <div class="bold"> {{ $i18n.get(`Reward ratios`) }}</div>
            </div>
        </div>

        <swiper ref="swiper-tablet" v-show="isTablet"
            :options="swiperOptionsTablet"
            @slide-next-transition-end="onSwiperNext"
            @slide-prev-transition-end="onSwiperPrev">

            <swiper-slide>
                <div class="table">
                    <div class="text">
                        {{ $i18n.get(`The rate is adjustable and depends on the staking period, your status, the amount, and the total amount of the staked funds.`) }}
                    </div>

                    <div class="container">
                        <div class="column -name">
                            <div class="cell" v-for="(type, index) of types" :key="index">
                                {{ $functions.firstSymbolToUpperCase(type) }}
                            </div>
                        </div>

                        <div class="column" v-for="(group, key) of convertRates" :key="key">
                            <div class="cell -name" :key="key + ' name'" v-html="$i18n.get(`{period, plural, one {# day} other {# days}}`, {period: group.period})"></div>

                            <div class="cell" v-for="(coefficient, key) of group.data" :key="key">
                                <svg v-if="coefficient" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="10" fill="#77DA29"/>
                                    <path d="M6.875 10.625L8.75 12.5L13.75 7.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                                <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="10" fill="#001A54" fill-opacity="0.2"/>
                                    <rect x="6" y="9" width="8" height="2" rx="1" fill="white"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>

            <swiper-slide>
                <div class="table">
                    <div class="text" v-html="$i18n.get(`The ratios in the table below are multipliers for the reward amount (<span>R</span>). Your reward depends on the staking period and your status. `)"></div>
                    
                    <div class="container">
                        <div class="column -name">
                            <div class="cell" v-for="(type, index) of types" :key="index">
                                {{ $functions.firstSymbolToUpperCase(type) }}
                            </div>
                        </div>

                        <div class="column" v-for="(group, key) of convertRates" :key="key">
                            <div class="cell -name" :key="key + ' name'" v-html="$i18n.get(`{period, plural, one {# day} other {# days}}`, {period: group.period})"></div>

                            <div class="cell" v-for="(coefficient, key) of group.data" :key="key">
                                <div v-if="coefficient" class="coefficient">
                                    x{{ coefficient }}
                                </div>
                                
                                <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="10" fill="#001A54" fill-opacity="0.2"/>
                                    <rect x="6" y="9" width="8" height="2" rx="1" fill="white"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>

    <div v-else-if="isMobile" class="table-swiper-mobile">
        <div class="title" v-html="$i18n.get(`Available periods <br> and reward ratios`)"></div>

        <div class="swiper-content">
            <div class="text" v-html="$i18n.get(`The ratios in the table below are multipliers for the reward amount (<span>R</span>). Your reward depends on the staking period and your status. `)"></div>
            
            <div class="swiper-header" ref="header">
                <div v-for="(period, index) of convertRates" :key="index" @click="swipeTo(index, 'swiper-mobile')" class="item" :class="{'-active': selectedSlide === index}">
                    <div class="bold">
                        {{ period.period }} {{ $i18n.get(`days`)}}
                    </div>
                </div>
            </div>

            <swiper ref="swiper-mobile"
                :options="swiperOptionsTablet"
                @slide-next-transition-end="onSwiperNext"
                @slide-prev-transition-end="onSwiperPrev">

                <swiper-slide v-for="(period, index) of convertRates" :key="index">
                    <div class="string" v-for="(coefficient, key) of period.data" :key="key">
                        <svg v-if="coefficient" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="10" fill="#77DA29"/>
                            <path d="M6.875 10.625L8.75 12.5L13.75 7.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="10" fill="#001A54" fill-opacity="0.2"/>
                            <rect x="6" y="9" width="8" height="2" rx="1" fill="white"/>
                        </svg>

                        <div class="status">
                            {{ setUpperString(key) }}
                        </div>
                        
                        <div v-if="coefficient" class="coefficient">
                            x{{ coefficient }}
                        </div>

                        <div v-else class="empty">
                            {{ $i18n.get(`Not available`) }}
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

const DEVICE_MAX_WIDTH = {
    MOBILE: 768,
    TABLET: 1280,
}

export default {
    name: 'info-tables',
    components: {
        'swiper': Swiper,
        'swiper-slide': SwiperSlide,
    },
    props: {
        rates: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            types: [
                'status',
                'member',
                'bronze',
                'silver',
                'gold',
                'platinum',
                'black'
            ],
            swiperOptionsTablet: {
                slidesPerView: 'auto',
                spaceBetween: 20,
                centeredSlides: true,
                loop: false,
            },
            selectedSlide: 0,
            innerWidth: 0
        }
    },
    computed: {
        convertRates() {
            const periods = [360, 270, 180, 90, 30]
            const statuses = ['member', 'bronze', 'silver', 'gold', 'platinum', 'black']
            const rates = []

            periods.map(period => {
                let periodRates = {}

                statuses.map(status => {
                    periodRates[status] = this.rates[status]['rate_' + period] * this.rates[status]['rate']
                })

                rates.push({
                    period: period,
                    data: periodRates
                })
            })

            return rates
        },
        isMobile() {
            return this.innerWidth < DEVICE_MAX_WIDTH.MOBILE
        },
        isTablet() {
            return this.innerWidth > DEVICE_MAX_WIDTH.MOBILE && this.innerWidth < DEVICE_MAX_WIDTH.TABLET
        },
        isDesktop() {
            return this.innerWidth >= DEVICE_MAX_WIDTH.TABLET
        },
    },
    methods: {
        onSwiperNext() {
            this.selectedSlide = this.selectedSlide + 1
            this.scrollHeader()
        },
        onSwiperPrev() {
            this.selectedSlide = this.selectedSlide - 1
            this.scrollHeader()
        },
        scrollHeader() {
            if (this.selectedSlide > 1) {
                this.$refs.header.scrollLeft = 1000
            }

            if (this.selectedSlide < 1) {
                this.$refs.header.scrollLeft = 0
            }
        },
        swipeTo(index, type) {
            const speed = this.selectedSlide - index
            this.selectedSlide = index

            if (this.$refs[type].$swiper) {
                this.$refs[type].$swiper.slideTo(index, 300 * Math.abs(speed), false)
            }

            this.scrollHeader()
        },
        setInnerWidth() {
            this.innerWidth = window.innerWidth
        },
        setUpperString(str) {
            return str[0].toUpperCase() + str.slice(1)
        }
    },
    created() {
        window.addEventListener('resize', this.setInnerWidth)
        this.setInnerWidth()
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setInnerWidth)
    }
}
</script>

<style lang="less">
.stacking-swap-info-tables {
    width: var(--main-width-full);
    margin: 0 auto;
    display: flex;
    column-gap: 40px;

    > .table-item {
        width: 570px;
        
        > .title {
            font-weight: 700;
            font-size: 32px;
            line-height: 48px;
        }

        > .table {
            margin-top: 25px;
            background-color: var(--white);
            padding: 35px 30px;
            border-radius: 20px;

            > .text {
                font-weight: normal;
                font-size: 14px;
                line-height: 25px;
                margin-bottom: 15px;

                > span {
                    font-weight: 800;
                    color: var(--red);
                }
            }

            > .container {
                display: flex;

                > .column {
                    width: 79px;
                    border-left: 1px solid var(--dark-blue-10);

                    > .cell {
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--main-grey);

                        > .coefficient {
                            color: var(--white);
                            background: var(--green);
                            padding: 0px 7px 0px 1px;
                            border-radius: 100px;
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 18px;
                            display: flex;
                            align-items: center;
                            

                            &::before {
                                content: 'R';
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 16px;
                                height: 16px;
                                border-radius: 100px;
                                background-color: var(--white);
                                color: var(--red);
                                margin-right: 5px;
                            }
                        }

                        &:nth-child(2n) {
                            background: none;
                        }

                        &.-name {
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 19px;
                        }
                    }

                    &.-name {
                        width: 114px;

                        > .cell {
                            border-radius: 100px 0px 0px 100px;
                            padding: 0px 20px;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 21px;
                            justify-content: flex-start;
                            

                            &:first-child {
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 19px;
                            }
                        }
                    }

                    &:last-child {                        
                        > .cell {
                            border-radius: 0px 100px 100px 0px;
                        }
                    }

                    &:first-child {
                        border-left: none;
                    }
                }
            }
        }
    }
}

.table-swiper-tablet {
    width: var(--main-width-full);
    margin: 0 auto;

    > .swiper-header {
        max-width: 100%;
        display: flex;
        column-gap: 30px;
        overflow: hidden;
        border-bottom: 1px solid rgba(0, 26, 84, 0.06);
        margin-bottom: 25px;

        > .item {
            font-weight: 700;
            font-size: 28px;
            line-height: 42px;
            height: 50px;
            color: var(--dark-blue);
            cursor: pointer;
            transition: all 0.2s;
            border: 1px solid transparent;
            white-space: nowrap;

            &.-active {
                color: var(--dark-blue-40);
                border-bottom: 2px solid var(--blue-link);
            }
        }
    }

    .table {
        background-color: var(--white);
        padding: 35px 30px;
        border-radius: 20px;

        > .text {
            font-weight: normal;
            font-size: 14px;
            line-height: 25px;
            margin-bottom: 20px;

            > span {
                font-weight: 800;
                color: var(--red);
            }
        }

        > .container {
            display: flex;
            > .column {
                width: 120px;
                border-left: 1px solid var(--dark-blue-10);

                > .cell {
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--main-grey);

                    > .coefficient {
                        color: var(--white);
                        background: var(--green);
                        border-radius: 100px;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 18px;
                        padding: 0px 7px 0px 1px;
                        display: flex;
                        align-items: center;
                        
                        &::before {
                            content: 'R';
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 16px;
                            height: 16px;
                            border-radius: 100px;
                            background-color: var(--white);
                            color: var(--red);
                            margin-right: 5px;
                        }
                    }

                    &:nth-child(2n) {
                        background: none;
                    }

                    &.-name {
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 19px;
                    }
                }

                &.-name {
                    width: 185px;

                    > .cell {
                        border-radius: 100px 0px 0px 100px;
                        padding: 0px 20px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        justify-content: flex-start;

                        &:first-child {
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 19px;
                        }
                    }
                }

                &:last-child {                        
                    > .cell {
                        border-radius: 0px 100px 100px 0px;
                    }
                }

                &:first-child {
                    border-left: none;
                }
            }
        }
    }
}

.table-swiper-mobile {
    > .title {
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
    }

    > .swiper-content {
        background-color: var(--white);
        border-radius: 20px;
        margin-top: 15px;

        .text {
            padding: 15px 15px 0px;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;

            > span {
                font-weight: 800;
                color: var(--red);
            }
        }

        > .swiper-header {
            padding: 15px 15px 0px 15px;
            max-width: 100%;
            display: flex;
            column-gap: 19px;
            overflow: hidden;
            border-bottom: 1px solid rgba(0, 26, 84, 0.06);

            > .item {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                height: 30px;
                color: var(--dark-blue);
                cursor: pointer;
                transition: all 0.2s;
                border: 1px solid transparent;
                white-space: nowrap;

                &.-active {
                    border-bottom: 2px solid var(--green);
                }
            }
        }

        .swiper-container {
            padding: 15px;

            .string {
                display: flex;
                align-items: center;
                column-gap: 7px;
                height: 40px;
                border-bottom: 1px solid var(--dark-blue-10);

                > .status {
                    flex-grow: 1;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;
                }

                > .coefficient {
                    color: var(--white);
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 18px;
                    border-radius: 100px;
                    background-color: var(--green);
                    display: flex;
                    align-items: center;
                    padding: 0px 7px 0px 1px;
                    
                    &::before {
                        content: 'R';
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 16px;
                        height: 16px;
                        border-radius: 100px;
                        background-color: var(--white);
                        color: var(--red);
                        margin-right: 5px;
                    }
                }

                > .empty {
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 16px;
                    color: var(--dark-blue-30);
                }

                &:last-child {
                    border: none;
                }
            }
        }
    }
}
</style>
