<template>
    <div class="reward-table">
        <div class="reward-head" :class="rewardHeadClass">
            <div v-if="disabled" class="disabled-notification">
                <div class="-top">Swap-staking will be available 1 month prior to IDO. Stay tuned!</div>
                <div class="-bottom"></div>
            </div>

            <div class="title">
                {{ $i18n.get(`Amount`) }}
            </div>

            <div class="payment">
                <slot name="default"></slot>
            </div>

            <div class="title -second">
                {{ $i18n.get(`Reward`) }}
            </div>

            <div class="description">
                {{ $i18n.get(`Available staking period depends on your status. The higher your status and the longer the period, the more you earn. You can upgrade your status later. The amount of the daily reward will be recalculated the next day after the status change.`) }}
            </div>
        </div>

        <div v-if="!$store.state.isMobile" class="reward-main-table">
            <div class="table-string -head">
                <div class="item"></div>
                <div class="item">360 {{ $i18n.get(`days`) }}</div>
                <div class="item">270 {{ $i18n.get(`days`) }}</div>
                <div class="item">180 {{ $i18n.get(`days`) }}</div>
                <div class="item">90 {{ $i18n.get(`days`) }}</div>
                <div class="item">30 {{ $i18n.get(`days`) }}</div>
            </div>

            <div class="table-string" v-for="(staking, index) of availableStaking" :key="index" :class="{'-current': staking.role === userRole}">
                <div class="item -title">
                    {{ $functions.firstSymbolToUpperCase(staking.role) }}

                    <div class="current" v-if="staking.role === userRole">
                        {{ $i18n.get(`your status`) }}
                    </div>

                    <router-link v-else :to="{ name: 'nft', params: {slug: staking.role}}" class="link">
                        <template v-if="$store.state.isDesktop">
                            {{ $i18n.get(`Upgrade to`) }} {{ staking.role }}
                        </template>
                        <template v-else>
                            {{ $i18n.get(`Upgrade`) }}
                        </template>
                    </router-link>
                </div>

                <div class="item" v-for="rate of staking.rates" :key="rate.period" :class="{'-active' : getActiveStaking(rate)}">
                    <template v-if="rate.rate > 0">
                        <div class="value">
                            ~ {{ $functions.getClearMoney(getPeriodReward(rate)) }} TFT
                        </div>

                        <div class="apr">
                            APR: {{ getYearApr(rate) }}%
                        </div>

                        <template v-if="staking.role === userRole">
                            <w-button v-if="getActiveStaking(rate)" @click="confirmAddStaking(rate)" type="green" size="small" :disabled="disabled">
                                {{ $i18n.get(`Add TFT`) }}
                            </w-button>

                            <w-button v-else @click="confirmStaking(rate)" type="orange" size="small" :disabled="disabled">
                                {{ $i18n.get(`Stake now`) }}
                            </w-button>
                            <span class="available-soon">Available soon</span>
                        </template>
                    </template>

                    <span v-else class="empty">
                        {{ $i18n.get(`not available`) }}
                    </span>
                </div>
            </div>
        </div>

        <div v-if="!$store.state.isMobile" class="comment"
            v-html="$i18n.get(`The indicator is calculated as current participants. <span>The amount might change.</span>`)">
        </div>

        <div v-show="$store.state.isMobile" class="swiper-content">
            <div class="swiper-header" ref="header">
                <div v-for="(period, index) of splitStatuses" @click="swipeTo(index, 'swiper')" :key="index" class="item" :class="{'-active': selectedSlide === index}">
                    <div class="bold">
                        {{ period.period }}
                    </div>
                </div>
            </div>

            <swiper ref="swiper"
                :options="swiperOptions"
                @slide-next-transition-end="onSwiperNext"
                @slide-prev-transition-end="onSwiperPrev">

                <swiper-slide v-for="(period, index) of splitStatuses" :key="index">
                    <div class="status" v-for="(status, index) of period.data" :key="index" :class="periodClasses(status)">
                        <template>
                            <div class="info">
                                <div class="title">
                                    {{ $functions.firstSymbolToUpperCase(status.status) }}

                                    <router-link v-if="userRole !== status.status" :to="{ name: 'nft', params: { slug: status.status}}" class="link">
                                        {{$i18n.get(`Upgrade to`)}} {{ $functions.firstSymbolToUpperCase(status.status) }}
                                    </router-link>

                                    <div v-else-if="userRole === status.status" class="current">
                                        {{ $i18n.get(`your status`) }}
                                    </div>
                                </div>

                                <div class="data">
                                    <template v-if="status.data.rate">
                                        <div class="value">
                                            ~ {{ $functions.getClearMoney(getPeriodReward(status.data)) }} TFT
                                        </div>
                                        <div class="apr">
                                            APR: {{ getYearApr(status.data) }} %
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="not-available">
                                            Not available
                                        </div>
                                    </template>
                                </div>
                            </div>

                            <template v-if="userRole === status.status && status.data.rate">
                                <w-button v-if="getActiveStaking(status.data)" @click="confirmAddStaking(status.data)" type="green" size="small" :disabled="disabled">
                                    {{ $i18n.get(`Add TFT`) }}
                                </w-button>

                                <w-button v-else @click="confirmStaking(status.data)" type="orange" size="small" :disabled="disabled">
                                    {{ $i18n.get(`Stake now`) }}
                                </w-button>
                                <span class="available-soon">Available soon</span>
                            </template>
                        </template>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    name: 'reward-table',
    components: {
        'swiper': Swiper,
        'swiper-slide': SwiperSlide,
    },
    props: {
        amount: {
            type: Number,
            default: 0
        },
        rates: {
            type: Object
        },
        info: {
            type: Object
        },
        active: {
            type: Array,
            default() {
                return {}
            }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            swiperOptions: {
                slidesPerView: 'auto',
                spaceBetween: 20,
                centeredSlides: true,
                loop: false,
            },
            selectedSlide: 0,
            innerWidth: 0
        }
    },
    computed: {
        userRole() {
            if (this.isGuest) {
                return false
            }

            return this.$store.state.user.status.role
        },
        convertRates() {
            const roles = []

            for (const role in this.rates) {
                if (role == 'member') {
                    continue
                }

                let rates = []

                for (const rate in this.rates[role]) {
                    if (rate == 'rate') {
                        continue
                    }

                    rates.push({
                        rate: this.rates[role]['rate'] * this.rates[role][rate],
                        period: rate
                    })
                }

                roles.push({
                    role: role,
                    rates: rates.reverse()
                })
            }

            return roles
        },
        availableStaking() {
            if (this.userRole === 'bronze') {
                return this.convertRates
            } else if (this.userRole === 'silver') {
                return this.convertRates.filter(s => s.role !== 'bronze')
            } else if (this.userRole === 'gold') {
                return this.convertRates.filter(s => s.role !== 'bronze' && s.role !== 'silver')
            } else if (this.userRole === 'platinum') {
                return this.convertRates.filter(s => s.role !== 'bronze' && s.role !== 'silver' && s.role !== 'gold')
            } else if (this.userRole === 'black') {
                return this.convertRates.filter(s => s.role === 'black')
            }
        },
        splitStatuses() {
            const period360 = []

            this.availableStaking.forEach(status => {
                period360.push({
                    status: status.role,
                    data: status.rates.find( item => item.period === 'rate_360')
                })
            })

            const period270 = []

            this.availableStaking.forEach(status => {
                period270.push({
                    status: status.role,
                    data: status.rates.find( item => item.period === 'rate_270')
                })
            })

            const period180 = []

            this.availableStaking.forEach(status => {
                period180.push({
                    status: status.role,
                    data: status.rates.find( item => item.period === 'rate_180')
                })
            })

            const period90 = []

            this.availableStaking.forEach(status => {
                period90.push({
                    status: status.role,
                    data: status.rates.find( item => item.period === 'rate_90')
                })
            })

            const period30 = []

            this.availableStaking.forEach(status => {
                period30.push({
                    status: status.role,
                    data: status.rates.find( item => item.period === 'rate_30')
                })
            })

            return [
                {
                    period: '360 days',
                    data: period360
                },
                {
                    period: '270 days',
                    data: period270
                },
                {
                    period: '180 days',
                    data: period180
                },
                {
                    period: '90 days',
                    data: period90
                },
                {
                    period: '30 days',
                    data: period30
                },
            ]

        },
        rewardHeadClass() {
            return {
                '-notification' : this.disabled && this.$store.state.isMobile
            }
        }
    },
    methods: {
        getActiveStaking(rate) {
            const period = Number(rate.period.split('_').pop())

            return this.active.find(staking => staking.period == period)
        },
        getYearApr(rate) {
            if (this.amount == 0) {
                return 0
            }

            const deposits = this.info.deposits + this.amount,
                // points = this.amount / deposits * rate.rate + this.info.points,
                // Уравнивание формулы
                points = 3.5,
                funds = this.info.fund * 365

            return Math.floor(funds * (((this.amount / deposits) * rate.rate) / points) / this.amount * 100)
        },
        getPeriodReward(rate) {
            const period = Number(rate.period.split('_').pop()),
                apr = this.getYearApr(rate) / 365 * period

            return Math.floor(this.amount / 100 * apr)
        },
        confirmStaking(rate) {
            const period = Number(rate.period.split('_').pop())

            this.$emit('confirm-staking', {
                period: period,
                amount: this.amount,
                apr: this.getYearApr(rate)
            })
        },
        confirmAddStaking(rate) {
            const period = Number(rate.period.split('_').pop())
            const staking = this.getActiveStaking(rate)

            this.$emit('confirm-add-staking', {
                period: period,
                end: staking.end,
                amount: this.amount,
                apr: this.getYearApr(rate)
            })
        },
        periodClasses(period) {
            return {
                '-current' : period.status === this.userRole,
                '-stacking': this.getActiveStaking(period.data) && period.status === this.userRole
            }
        },
        onSwiperNext() {
            this.selectedSlide = this.selectedSlide + 1
            this.scrollHeader()
        },
        onSwiperPrev() {
            this.selectedSlide = this.selectedSlide - 1
            this.scrollHeader()
        },
        scrollHeader() {
            if (this.selectedSlide > 1) {
                this.$refs.header.scrollLeft = 1000
            }

            if (this.selectedSlide < 1) {
                this.$refs.header.scrollLeft = 0
            }
        },
        swipeTo(index, type) {
            const speed = this.selectedSlide - index
            this.selectedSlide = index

            if (this.$refs[type].$swiper) {
                this.$refs[type].$swiper.slideTo(index, 300 * Math.abs(speed), false)
            }

            this.scrollHeader()
        }
    }
}
</script>

<style lang="less">
.reward-table {
    margin-top: 20px;

    > .reward-head {
        padding: 30px 40px;
        background-color: var(--white);
        border-radius: 30px 30px 0px 0px;

        > .disabled-notification {
            display: none;
        }

        > .title {
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;

            &.-second {
                margin-top: 30px;
            }
        }

        > .payment {
            margin-top: 15px;
        }

        > .description {
            font-size: 16px;
            line-height: 28px;
            max-width: 100%;
            margin-top: 10px;
        }
    }

    > .reward-main-table {
        background-color: var(--white);

        > .table-string {
            display: flex;
            align-items: center;
            padding: 10px 40px;
            column-gap: 30px;
            border-bottom: 1px solid var(--dark-blue-10);

            > .item {
                width: 150px;
                display: flex;
                flex-direction: column;
                align-items: center;
                row-gap: 2px;
                padding: 20px 10px 10px;

                > .value {
                    max-width: 150px;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 24px;
                    white-space: nowrap;
                    overflow: hidden;
                }

                > .apr {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                }

                > .empty {
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--dark-blue-30);
                }

                > .btn {
                    width: 100%;
                }

                > .available-soon {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: var(--dark-blue-40)
                }

                &:first-child {
                    flex-grow: 1;
                    align-items: flex-start;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 21px;

                    > a {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                    }

                    > .current {
                        color: var(--dark-blue-60);
                        font-style: italic;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                    }
                }
            }

            &.-head {
                > .item {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;

                }

            }

            &.-current {
                background: #F1FBEA;

                > .item {
                    &.-active {
                        border: 3px solid var(--green);
                        border-radius: 20px;
                        position: relative;

                        &::before {
                            width: 54px;
                            left: calc(50% - 27px);
                            position: absolute;
                            top: 0;
                            content: 'ACTIVE';
                            padding: 1px 12px;
                            border-radius: 0px 0px 100px 100px;
                            font-weight: bold;
                            font-size: 8px;
                            line-height: 144%;
                            color: var(--white);
                            background-color: var(--green);
                        }
                    }
                }
            }

        }
    }

    > .comment {
        border-radius: 0px 0px 30px 30px;
        background-color: var(--white);
        padding: 20px 50px;
        font-weight: 400;
        font-size: 14px;
        line-height: 29px;
        color: var(--dark-blue-60);

        > span {
            color: var(--dark-blue)
        }
    }
}

@media (max-width: 1279px) {
    .reward-table {
        margin-top: 20px;

        > .reward-head {
            padding: 30px 40px;
            background-color: var(--white);
            border-radius: 30px 30px 0px 0px;

            > .payment {
                margin-top: 15px;
            }

            > .description {
                margin-top: 10px;
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                max-width: 990px;
            }
        }

        > .reward-main-table {
            background-color: var(--white);

            > .table-string {
                display: flex;
                align-items: center;
                padding: 10px 40px;
                column-gap: 5px;

                > .item {
                    width: 110px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    row-gap: 2px;
                    padding: 15px 5px 5px;


                    > .value {
                        max-width: 110px;
                        font-size: 14px;
                        line-height: 21px;
                    }

                    > .apr {
                        font-size: 14px;
                        line-height: 21px;
                    }

                    > .empty {
                        font-size: 13px;
                        line-height: 19px;
                    }

                    > .btn {
                        font-size: 14px;
                        padding: 10px;
                        line-height: 21px;
                        font-size: 14px;
                        line-height: 21px;
                    }

                    &:first-child {
                        flex-grow: 1;
                    }

                    &.-title {
                        align-items: flex-start;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;

                        > .link {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 18px;
                        }

                        > .current {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                        }
                    }
                }

                &.-head {
                    > .item {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                    }

                }

                &.-current {
                    background: #F1FBEA;

                    > .item {
                        &.-active {
                            border: 3px solid var(--green);
                            border-radius: 20px;
                        }
                    }
                }
            }
        }

        > .comment {
            border-radius: 0px 0px 30px 30px;
            background-color: var(--white);
            padding: 20px 50px;
            font-weight: 400;
            font-size: 14px;
            line-height: 29px;
            color: var(--dark-blue-60);

            > span {
                color: var(--dark-blue)
            }
        }
    }
}

@media (max-width: 1023px) {
    .reward-table {
        margin-top: 20px;

        > .reward-head {
            padding: 30px 30px;
            background-color: var(--white);
            border-radius: 30px 30px 0px 0px;

            > .title {
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;

                &.-second {
                    margin-top: 30px;
                }
            }

            > .payment {
                margin-top: 15px;
            }

            > .description {
                font-size: 16px;
                line-height: 28px;
                max-width: 100%;
                margin-top: 10px;
            }
        }

        > .reward-main-table {
            background-color: var(--white);

            > .table-string {
                display: flex;
                align-items: center;
                padding: 0px 30px;
                column-gap: 0px;

                > .item {
                    width: 100px;
                    padding: 15px 5px;
                    flex-shrink: 0;

                    > .value {
                        max-width: 90px;
                        font-weight: bold;
                        font-size: 13px;
                        line-height: 19px;
                        max-width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                    }

                    > .apr {
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 19px;
                    }

                    > .empty {
                        padding: 5px;
                        font-size: 13px;
                        line-height: 16px;
                        text-align: center;
                    }

                    > .btn {
                        height: 35px;
                        width: 100%;
                        font-size: 12px;
                        line-height: 18px;
                    }

                    &:first-child {
                        flex-grow: 1;
                        width: 90px;
                    }

                    &.-title {
                        width: 90px;
                        font-size: 14px;
                        line-height: 21px;
                    }
                }

                &.-current {
                    background: #F1FBEA;

                    > .item {
                        &.-active {
                            border: none;
                            border-radius: 20px;
                        }
                    }
                }

                &.-head {
                    > .item {
                        font-size: 14px;
                        line-height: 21px;
                    }
                }
            }
        }

        > .comment {
            border-radius: 0px 0px 30px 30px;
            background-color: var(--white);
            padding: 20px 50px;
            font-weight: 400;
            font-size: 14px;
            line-height: 29px;
            color: var(--dark-blue-60);

            > span {
                color: var(--dark-blue)
            }
        }
    }
}

@media (max-width: 767px) {
    .reward-table {
        margin-top: 0px;
        padding: 15px;

        > .reward-head {
            padding: 15px;
            background-color: var(--white);
            border-radius: 30px 30px 0px 0px;
            position: relative;

            &.-notification {
                padding-top: 60px;
            }

            > .disabled-notification {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 65px;
                padding: 7px 20px;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                background: var(--orange);
                border-radius: 20px 20px 0px 0px;


                > .-top {
                    text-align: center;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    color: var(--white);
                }

                > .-bottom {
                    height: 20px;
                    border-radius: 20px 20px 0 0;
                    background-color: var(--white);
                    position: absolute;
                    bottom: 0px;
                    right: 0;
                    left: 0;

                }
            }

            > .title {
                font-size: 14px;
                line-height: 21px;

                &.-second {
                    margin-top: 15px;
                }
            }

            > .payment {
                margin-top: 15px;
            }

            > .description {
                font-size: 12px;
                line-height: 20px;
                max-width: 100%;
                margin-top: 10px;
            }
        }

        > .swiper-content {
            background-color: var(--white);
            border-radius: 0px 0px 20px 20px;

            > .swiper-header {
                padding: 15px 15px 0px 15px;
                max-width: 100%;
                display: flex;
                column-gap: 19px;
                overflow: hidden;
                border-bottom: 1px solid rgba(0, 26, 84, 0.06);

                > .item {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    height: 30px;
                    color: var(--dark-blue);
                    cursor: pointer;
                    transition: all 0.2s;
                    border: 1px solid transparent;
                    white-space: nowrap;

                    &.-active {
                        border-bottom: 2px solid var(--blue-link);
                    }
                }
            }

            .swiper-container {

                .status {
                    padding: 15px;
                    border-bottom: 1px solid var(--dark-blue-10);
                    font-weight: 300;
                    min-height: 67px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    > .info {
                        display: flex;
                        align-self: stretch;
                        justify-content: space-between;
                        align-items: center;

                        > .title {
                            display: flex;
                            flex-direction: column;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 18px;

                            > a {
                                font-weight: 500;
                                font-size: 11px;
                                line-height: 16px;
                            }

                            > .current {
                                font-style: italic;
                                font-weight: 500;
                                font-size: 11px;
                                line-height: 16px;
                                color: var(--dark-blue-60)
                            }
                        }

                        > .data {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            justify-content: center;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 18px;
                            color: var(--dark-blue-60);

                            .not-available {
                                font-weight: 400;
                                font-size: 11px;
                                line-height: 16px;
                                color: var(--dark-blue-30);
                            }
                        }
                    }

                    > .btn {
                        width: 100%;
                        margin-top: 10px;
                    }

                    > .available-soon {
                        font-weight: 400;
                        font-size: 11px;
                        line-height: 16px;
                        margin-top: 6px;
                        color: var(--dark-blue-40)
                    }

                    &:last-child {
                        border-bottom: none;
                    }

                    &.-current {
                        background: #F1FBEA;
                    }

                    &.-stacking {
                        position: relative;

                        &::before {
                            width: 54px;
                            left: calc(50% - 27px);
                            position: absolute;
                            top: 0;
                            content: 'ACTIVE';
                            padding: 1px 12px;
                            border-radius: 0px 0px 100px 100px;
                            font-weight: bold;
                            font-size: 8px;
                            line-height: 144%;
                            color: var(--white);
                            background-color: var(--green);
                        }
                    }
                }
            }
        }
    }
}
</style>
