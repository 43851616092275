<template>
    <div class="active-staking">
        <div class="head">
            <div class="staking-info">
                <div class="main">
                    {{ $i18n.get(`Earned:`) }}

                    <span class="earned">
                        {{ $functions.getClearMoney(getStaksEarned) }} TFT
                    </span>

                    <span class="apr">
                        {{ $i18n.get(`APR`) }}:
                        <span>{{ getStaksAPR.toFixed(2) }}%</span>
                    </span>
                </div>

                <div class="amount">
                    {{ $i18n.get(`Amount of tokens staked:`) }} {{ $functions.getClearMoney(getStaksAmount) }} TFT
                </div>
            </div>

            <div class="user-info" v-if="!$store.state.isMobile">
                <div class="user">
                    <w-status-card-icon :status="userRole"></w-status-card-icon>
                    {{ $functions.firstSymbolToUpperCase(userRole) }} {{ $i18n.get(`Status`) }}

                    <w-button v-if="userRole !== 'black'" :to="{name: 'market'}" type="white-border" size="xs">
                        {{ $i18n.get(`Upgrade`) }}
                    </w-button>
                </div>

                <div class="description" v-if="userRole !== 'black'">
                    {{ $i18n.get(`The higher your status, the more you earn`) }}
                </div>
            </div>
        </div>

        <div v-if="isUserLoad" class="items">
            <div class="stak" v-for="(stak, index) of activeStaks" :key="index" :class="{'-done': isDone(stak)}">
                <div class="main-info">

                    <div v-if="stak.type === METHOD.STAKING" class="amount">
                        {{ $functions.getClearMoney(stak.amount) }} TFT
                    </div>

                    <div v-else-if="stak.type === METHOD.SWAP" class="swap">
                        <p>
                            {{ $i18n.get(`Swapped:`) }} {{ $functions.getClearMoney(stak.swap.amount) }} TFL
                        </p>

                        <p>
                            {{ $i18n.get(`You will receive:`) }} {{ $functions.getClearMoney((stak.swap.amount * stak.swap.multiplier)) }} TFT
                        </p>
                    </div>

                    <div class="earned">
                        {{ $i18n.get(`Earned:`) }}

                        <span>{{ $functions.getClearMoney(stak.earned) }} TFT</span>
                    </div>

                    <div class="apr">
                        {{ $i18n.get(`APR`) }}:

                        <span>{{ stak.apr.toFixed(2) }}%</span>
                    </div>

                    <div v-if="$store.state.isMobile" class="mobile-diagram">
                        <div class="info">
                            <div class="period">
                                {{ stak.period }} {{ $i18n.get(`days`) }}
                            </div>

                            <div class="passed">
                                {{ stak.passed }} {{ $i18n.get(`passed`) }}
                            </div>
                        </div>

                        <div v-if="!isDone(stak)" class="diagram-container">
                            <div class="value" :style="diagramValue(stak.passed, stak.period)"></div>
                        </div>
                    </div>

                    <template v-if="!isDone(stak)">
                        <div class="open">
                            {{ $i18n.get(`Opening date:`) }}

                            <span>
                                {{ $moment.unix(stak.start).utcOffset(user.timeZone).format('DD.MM.YYYY') }}
                            </span>
                        </div>

                        <div class="close">
                            {{ $i18n.get(`Ends:`) }}

                            <span>
                                {{ $moment.unix(stak.end).utcOffset(user.timeZone).format('DD.MM.YYYY') }}
                            </span>
                        </div>
                    </template>

                    <w-button v-else @click="unstake(stak)" :disabled="hasUnstakeRequest" type="green" size="middle">
                        <template v-if="hasUnstakeRequest">
                            {{ $i18n.get(`Unstake`) }}...

                            <w-loader type="ring" color="white"></w-loader>
                        </template>

                        <template v-else>
                            {{ $i18n.get(`Unstake`) }}
                        </template>
                    </w-button>
                </div>

                <div class="circle" v-if="!$store.state.isMobile">
                    <vue-circle class="diagramm"
                        :progress="percentage(stak.passed, stak.period)"
                        :size="130"
                        :reverse="false"
                        line-cap="round"
                        :fill="{color: '#77DA29'}"
                        empty-fill="rgba(255, 255, 255, .1)"
                        :animation-start-value="0.0"
                        :start-angle="165"
                        insert-mode="append"
                        :thickness="5"
                        :show-percent="true">
                    </vue-circle>

                    <div class="text">
                        <div class="period">
                            {{ stak.period }} {{ $i18n.get(`days`) }}
                        </div>

                        <div class="passed">
                            {{ stak.passed }} {{ $i18n.get(`passed`) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="user-info" v-if="$store.state.isMobile">
            <div class="user">
                <w-status-card-icon :status="userRole"></w-status-card-icon>
                {{ $functions.firstSymbolToUpperCase(userRole) }} {{ $i18n.get(`Status`) }}

                <w-button v-if="userRole !== 'black'" :to="{name: 'market'}" type="white-border" size="xs">
                    {{ $i18n.get(`Upgrade`) }}
                </w-button>
            </div>

            <div class="description">
                {{ $i18n.get(`The higher your status, the more you earn`) }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import VueCircle from 'vue2-circle-progress'

const METHOD = {
    STAKING: 'staking',
    SWAP: 'swap',
}

export default {
    name: 'active-staking',
    components: {
        'vue-circle': VueCircle
    },
    props: {
        activeStaks: {
            type: Array,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            hasUnstakeRequest: false
        }
    },
    computed: {
        ...mapGetters('user', {
            isUserLoad: 'isLoad',
            user: 'get'
        }),
        userRole() {
            if (this.isGuest) {
                return false
            }

            return this.$store.state.user.status.role
        },
        getStaksAmount() {
            return this.activeStaks.reduce((sum, staking) => {
                return sum + staking.amount
            }, 0)
        },
        getStaksEarned() {
            return this.activeStaks.reduce((sum, staking) => {
                return sum + staking.earned
            }, 0)
        },
        getStaksAPR() {
            return this.activeStaks.reduce((sum, staking) => {
                return sum + staking.apr
            }, 0)
        }
    },
    methods: {
        percentage(partialValue, totalValue) {
            return this.$functions.percentage(partialValue, totalValue)
        },
        diagramValue(pased, period) {
            let percent = this.$functions.percentage(pased, period)

            if (percent >= 100) {
                return `width: 100%;`
            } else {
                return `width: ${percent}%;`
            }
        },
        isDone(staking) {
            return staking.end * 1000 < Date.now();
        },
        unstake(staking) {
            this.hasUnstakeRequest = true

            let url = '/earn/staking/unstake'

            if (staking.type == METHOD.SWAP) {
                url = '/earn/swap/unstake'
            }

            const form = {
                period: staking.period
            }

            this.$request.post(url, form, () => {
                this.hasUnstakeRequest = false

                this.$modal.show(this.$options.components['WStatusModal'], {
                    status: true,
                    title: 'You have successfully staked your funds',
                    button: 'OK, thanks!'
                },
                {
                    name: 'w-status-modal',
                    width: 450
                })

                this.$emit('unstake')
            }, () => {
                this.$modal.show(this.$options.components['WStatusModal'], {
                    status: false,
                    title: 'Something wrong',
                    description: this.$i18n.get(`Please try again or contact <a href="{ link }" target="_blank">Support Team</a>.`, { link: this.$store.state.links.telegramCommunity }),
                    button: 'Try again'
                },
                {
                    name: 'w-status-modal',
                    width: 450
                })
            })
        }
    },
    created() {
        this.METHOD = METHOD
    }
}
</script>

<style lang="less">
.active-staking {
    width: var(--main-width-full);
    margin: -200px auto 40px;
    min-height: 360px;
    background-image: url(../images/active-staking-bg.webp);
    background-size: 105% cover;
    background-position: center bottom;
    border-radius: 30px;
    padding: 25px 50px 50px;
    color: var(--white);

    > .head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > .staking-info {
            font-weight: 600;
            font-size: 22px;
            line-height: 33px;

            > .main {
                > .earned {
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 33px;
                    color: var(--green);
                }

                > .apr {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--white-40);

                    > span {
                        color: var(--white);
                    }
                }
            }

            > .amount {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
            }
        }

        > .user-info {
            > .user {
                display: flex;
                align-items: center;
                column-gap: 10px;
                font-weight: 700;
                font-size: 14px;
                line-height: 21px;
                justify-content: flex-end;
            }

            > .description {
                margin-top: 10px;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: var(--white-60);
            }
        }
    }

    > .items {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        > .stak {
            padding: 20px 30px;
            border-radius: 20px;
            background-color: var(--white-10);
            width: calc(50% - 10px);
            min-height: 190px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            > .main-info {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;

                > .amount {
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 33px;
                }

                > .earned {
                    > span {
                        font-weight: 700;
                        color: var(--green);
                    }
                }

                > .open, > .close {
                    font-size: 14px;
                    line-height: 24px;
                    color: var(--white-60);

                    > span {
                        color: var(--white);
                    }
                }

                > .open {
                    margin-top: 10px;
                }

                > .btn {
                    margin-top: 10px;
                }
            }

            > .circle {
                position: relative;

                .circle-percent-text-body {
                    display: none;
                }

                > .diagramm {
                    transform: rotate(180deg);
                }

                > .text {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    > .period {
                        text-align: center;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                    }

                    > .passed {
                        text-align: center;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }

            &.-done {
                border-top: 2px solid var(--green);

                > .circle {
                    > .diagramm {
                        &::before {
                            position: absolute;
                            content: '';
                            bottom: 5px;
                            left: 5px;
                            width: 30px;
                            height: 30px;
                            transform: rotate(180deg);
                            background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' fill='%2377DA29'/%3E%3Cpath d='M10.3125 15.9375L13.125 18.75L20.625 11.25' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1279px) {
    .active-staking {
        padding: 25px 40px 40px;

        > .head {
            > .staking-info {
                > .main {
                    > .earned {
                        font-size: 28px;
                    }
                }

                > .apr {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        > .items {
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            > .stak {
                padding: 20px 25px;

                > .main-info {
                    font-size: 14px;
                    line-height: 21px;

                    > .amount {
                        font-size: 22px;
                        line-height: 33px;
                    }

                    > .open, > .close {
                        font-size: 12px;
                        line-height: 22px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .active-staking {
        padding: 25px 40px 40px;

        > .head {
            > .staking-info {
                font-size: 18px;
                line-height: 27px;

                > .main {
                    > .earned {
                        font-size: 24px;
                    }
                }

                > .apr {
                    font-size: 14px;
                    line-height: 21px;
                }

                > .amount {
                    font-size: 14px;
                    line-height: 21px;
                }
            }
        }

        > .items {
            display: flex;
            flex-direction: column;

            > .stak {
                padding: 20px 25px;
                width: 100%;

                > .main-info {
                    font-size: 14px;
                    line-height: 21px;

                    > .amount {
                        font-size: 22px;
                        line-height: 33px;
                    }

                    > .open, > .close {
                        font-size: 12px;
                        line-height: 22px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .active-staking {
        padding: 15px;
        margin: -35px -15px 15px -15px;
        width: calc(100% + 30px);
        border-radius: 20px 20px 0px 0px;

        > .head {
            flex-direction: column;

            > .staking-info {
                font-size: 18px;
                line-height: 27px;

                > .main {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;

                    > .earned {
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 36px;
                    }

                    > .apr {
                        font-size: 14px;
                        line-height: 21px;
                    }
                }
            }
        }

        > .items {
            display: flex;
            flex-direction: column;

            > .stak {
                padding: 20px;
                width: 100%;
                min-height: 170px;
                position: relative;
                align-items: flex-start;
                flex-direction: column;

                > .main-info {
                    width: 100%;
                    font-size: 16px;
                    line-height: 20px;

                    > .amount {
                        font-size: 16px;
                        line-height: 20px;
                    }

                    > .open, > .close {
                        font-size: 12px;
                        line-height: 20px;
                    }

                    > .apr {
                        position: absolute;
                        right: 20px;
                        top: 20px;
                        font-size: 12px;
                        line-height: 18px;
                    }

                    > .btn {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        width: 100%;
                        height: 40px;
                    }

                    > .mobile-diagram {
                        > .info {
                            display: flex;
                            column-gap: 7px;
                            align-items: flex-end;
                            margin-top: 10px;

                            > .period {
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 14px;
                                color: var(--white);
                            }

                            > .passed {
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 13px;
                            }
                        }

                        > .diagram-container {
                            margin-top: 10px;
                            background: var(--white-20);
                            border-radius: 100px;
                            height: 5px;

                            > .value {
                                height: 100%;
                                background: var(--green);
                                border-radius: 100px;
                            }
                        }
                    }
                }
            }
        }

        > .user-info {
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin-top: 10px;
            align-items: center;

            > .user {
                display: flex;
                align-items: center;
                column-gap: 7px;
                font-weight: 700;
                font-size: 12px;
                line-height: 18px;
            }

            > .description {
                font-weight: 500;
                font-size: 11px;
                line-height: 16px;
            }
        }
    }
}
</style>
