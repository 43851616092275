<template>
    <w-modal class="-confirm-staking">
        <template #title>
            {{ $i18n.get(`Add`) }} TFT
        </template>

        <div class="params">
            <div class="param">
                <div class="title">
                    {{ $i18n.get(`Tokens`) }}
                </div>

                <div class="description -strong">
                    {{ $functions.getClearMoney(amount) }} TFT
                </div>
            </div>

            <div class="param">
                <div class="title">
                    {{ $i18n.get(`End date`) }}
                </div>

                <div class="description">
                    {{ getEndDate }}
                </div>
            </div>
        </div>

        <div class="terms">
            <w-checkbox v-model="isAccepted">
                <span
                    v-html='$i18n.get(`I have carefully read staking <a href="/terms-swap" target="_blank">Тerms and Сonditions</a> thus I understood that tokens will only be available once staking period expires.`)'>
                </span>
            </w-checkbox>
        </div>

        <div class="buttons">
            <w-button v-if="!hasSaveRequest" @click="close()" type="border" block>
                {{ $i18n.get(`Cancel`) }}
            </w-button>

            <w-button @click="confirm()" :disabled="validateStake || hasSaveRequest" type="blue" block>
                <template v-if="hasSaveRequest">
                    {{ $i18n.get(`Staking...`) }}

                    <w-loader type="ring" color="white"></w-loader>
                </template>
                <template v-else>
                    {{ $i18n.get(`Stake`) }}
                </template>
            </w-button>
        </div>
    </w-modal>
</template>

<script>
import { mapGetters } from 'vuex'

const METHOD = {
    STAKING: 'staking',
    SWAP: 'swap',
}

export default {
    name: 'confirm-add-staking',
    props: {
        method: {
            type: String,
            default: METHOD.STAKING
        },
        period: {
            type: Number,
            default: ''
        },
        end: {
            type: Number,
            default: ''
        },
        amount: {
            type: Number,
            default: 0
        },
        callback: {
            type: Function
        }
    },
    data() {
        return {
            hasSaveRequest: false,
            isAccepted: false
        }
    },
    computed: {
        ...mapGetters('user', {
            user: 'get'
        }),
        getEndDate() {
            return this.$moment.unix(this.end).utcOffset(this.user.timeZone).format('DD.MM.YYYY')
        },
        validateStake() {
            return !this.isAccepted
        }
    },
    methods: {
        close() {
            this.$parent.close()
        },
        confirm() {
            this.hasSaveRequest = true

            let url = '/earn/staking/add'

            if (this.method == METHOD.SWAP) {
                url = '/earn/swap/add'
            }

            const form = {
                amount: this.amount,
                period: this.period
            }

            this.$request.post(url, form, () => {
                this.$parent.close()

                this.$modal.show(this.$options.components['WStatusModal'], {
                    status: true,
                    title: 'You have successfully staked your funds',
                    button: 'OK, thanks!'
                },
                {
                    name: 'w-status-modal',
                    width: 450
                })

                this.callback()
            }, () => {
                this.$parent.close()

                this.$modal.show(this.$options.components['WStatusModal'], {
                    status: false,
                    title: 'Something wrong',
                    description: this.$i18n.get(`Please try again or contact <a href="{ link }" target="_blank">Support Team</a>.`, { link: this.$store.state.links.telegramCommunity }),
                    button: 'Try again'
                },
                {
                    name: 'w-status-modal',
                    width: 450
                })
            })
        }
    }
}
</script>

<style lang="less" scoped>
.-confirm-staking {
    padding-bottom: 50px;
}

.params {
    margin-top: 20px;
    border-radius: 20px;
    background-color: var(--main-grey);
    padding: 21px 24px 29px 29px;
    font-weight: 500;
    font-size: 16px;
    line-height: 144%;

    > .param {
        display: flex;
        justify-content: space-between;
        position: relative;

        > .title {
            padding: 5px;
            background-color: var(--main-grey);
            z-index: 1;
        }

        > .description {
            padding: 5px;
            background-color: var(--main-grey);
            z-index: 1;

            &.-strong {
                font-weight: 700;
            }
        }

        &::after {
            content: '';
            width: 100%;
            height: 1px;
            border-bottom: 1px dashed var(--dark-blue-20);
            position: absolute;
            bottom: 11px;
        }
    }
}

.terms {
    margin-top: 20px;
}

.buttons {
    margin-top: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}

@media (max-width: 400px) {
    .-confirm-staking {
        padding-bottom: 20px;
    }

    .params {
        margin-top: 10px;
    }

    .terms {
        margin-top: 10px;
    }

    .buttons {
        margin-top: 15px;
    }
}
</style>
