<template>
    <div class="swap-page">
        <div class="swap-offer-block">
            <div class="container">
                <div class="title"
                    v-html="$i18n.get(`Swap TFL-TFT <br>and get rewards`)">
                </div>

                <div class="description"
                    v-html="$i18n.get(`You can swap your TFL tokens and <span>get x5 in TFT.</span> Also you will receive a reward for staking. Available staking period depends on your status (30-360 days). The rate is adjustable and depends on the staking period, your status, the amount, and the total amount of the staked funds. This function is available to users with Bronze status and above.`)">
                </div>

                <div v-if="isGuest || userRole === 'member'" class="warning-block">
                    {{ $i18n.get(`You need to buy status-card to receive staking rewards`) }}

                    <w-button :to="{name: 'market'}" type="blue">
                        {{ $i18n.get(`Buy Status`) }}
                    </w-button>
                </div>
            </div>
        </div>

        <div class="content" :class="{'-padding': isGuest || userRole === 'member'}">
            <active-staking v-if="active.length"
                @unstake="load()"
                :active-staks="active">
            </active-staking>

            <info-tables v-if="!isLoading" :rates="rates"></info-tables>

            <div class="status-info-banner" v-if="isGuest || userRole === 'member'">
                <div class="text"
                    v-html="$i18n.get(`You need to buy status-card <br> to receive staking rewards`)">
                </div>

                <w-button :to="{name: 'market'}" type="blue" :size="$store.state.isMobile ? 'small': 'large'">
                    {{ $i18n.get(`Buy Status`) }}
                </w-button>
            </div>
        </div>

        <div v-if="isAssetsLoad && tflBalance && !isGuest && userRole !== 'member'" class="swapping-table-container">
            <div class="container">
                <div class="title">
                    <div>{{ $i18n.get(`Swap & staking`) }}</div>
                    <div v-if="disabled" class="-additional">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="9" cy="9" r="9" fill="white"/>
                            <path d="M10.304 4L10.064 9.748H8.324L8.084 4H10.304ZM9.224 12.82C8.864 12.82 8.568 12.716 8.336 12.508C8.112 12.292 8 12.028 8 11.716C8 11.396 8.112 11.128 8.336 10.912C8.568 10.696 8.864 10.588 9.224 10.588C9.576 10.588 9.864 10.696 10.088 10.912C10.32 11.128 10.436 11.396 10.436 11.716C10.436 12.028 10.32 12.292 10.088 12.508C9.864 12.716 9.576 12.82 9.224 12.82Z"
                                  fill="#EEB012"/>
                        </svg>
                        <span>Swap-staking will be available 1 month prior to IDO. Stay tuned!</span>
                    </div>
                </div>

                <reward-table
                    @confirm-staking="confirmStaking"
                    @confirm-add-staking="confirmAddStaking"
                    :active="active"
                    :amount="tflBalance * 5"
                    :rates="rates"
                    :disabled="disabled"
                    :info="info">

                    <template #default>
                        <div class="swap-calc">
                            <div class="currency">{{ $functions.getClearMoney(tflBalance) }} TFL</div>
                            =
                            <div class="currency">{{ $functions.getClearMoney(tflBalance * 5) }} TFT</div>
                        </div>
                    </template>
                </reward-table>
            </div>
        </div>

        <history-table v-if="history.length" :history-staks="history" type="swap"></history-table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import InfoTables from '../staking/snippets/InfoTables.vue'
import RewardTable from '../staking/snippets/RewardTable.vue'
import HistoryTable from '../staking/snippets/HistoryTable.vue'
import ActiveStaking from '../staking/snippets/ActiveStaking.vue'

export default {
    name: 'swap',
    components: {
        'info-tables': InfoTables,
        'reward-table': RewardTable,
        'history-table': HistoryTable,
        'active-staking': ActiveStaking
    },
    data() {
        return {
            isLoading: true,
            apr: 0,
            rates: {},
            info: {},
            active: [],
            history: [],
            disabled: false
        }
    },
    computed: {
        ...mapGetters('user', {
            isUserLoad: 'isLoad'
        }),
        ...mapGetters('assets', {
            isAssetsLoad: 'isLoad',
            tflBalance: 'getUserTflBalance'
        }),
        isGuest() {
            return this.$store.state.isGuest
        },
        userRole() {
            return this.$store.state.user.status.role
        }
    },
    methods: {
        confirmStaking(params) {
            this.$modal.show(() => import(/* webpackChunkName: 'staking' */ '../staking/modals/ConfirmStaking.vue'), Object.assign(params, {
                method: 'swap',
                callback: this.load
            }), {
                name: 'confirm-staking',
                width: 450
            })
        },
        confirmAddStaking(params) {
            this.$modal.show(() => import(/* webpackChunkName: 'staking' */ '../staking/modals/ConfirmAddStaking.vue'), Object.assign(params, {
                method: 'swap',
                callback: this.load
            }), {
                name: 'confirm-staking',
                width: 450
            })
        },
        load() {
            this.$request.get('/earn/swap', {}, response => {
                this.$store.dispatch('assets/load')

                this.isLoading = false

                this.apr = response.staking.apr
                this.rates = response.staking.rates
                this.info = response.staking.info
                this.active = response.staking.active
                this.history = response.staking.history
                this.disabled = response.disabled
            }, () => {
                return this.$router.push('/404')
            })
        }
    },
    created() {
        this.load()
    }
}
</script>

<style lang="less">
.swap-page {
    padding-bottom: 40px;
    background-color: var(--main-grey);

    > .swap-offer-block {
        min-height: 530px;
        color: var(--white);
        background-color: var(--dark-blue);
        background-image: url(./images/swap-offer-bg.webp);
        background-size: cover;
        background-position: 60%;
        background-repeat: no-repeat;
        padding: 50px;

        > .container {
            width: var(--main-width-full);
            margin: 0 auto;

            > .title {
                font-weight: 700;
                font-size: 46px;
                line-height: 50px;
            }

            > .description {
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                color: var(--white-80);
                margin-top: 20px;
                max-width: 530px;

                > span {
                    color: var(--white);
                    font-weight: 800;
                }
            }

            > .warning-block {
                width: 490px;
                margin-top: 30px;
                background: var(--white-10);
                backdrop-filter: blur(40px);
                padding: 30px;
                border-radius: 20px;
                border: 1px solid var(--white-10);
                display: inline-flex;
                column-gap: 15px;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                align-items: center;

                > .btn {
                    flex-shrink: 0;
                }
            }
        }
    }

    > .content {
        background-color: var(--main-grey);
        padding: 80px 0px 0px;

        > .status-info-banner {
            margin-top: 40px;
            width: var(--main-width-full);
            margin: 40px auto 0px;
            background-image: url(../staking/images/status-info-banner-bg.webp);
            background-size: 105% 105%;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 20px;
            padding: 40px;
            display: flex;
            justify-content: center;


            > .text {
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                color: var(--white);
                margin-right: 20px;
            }
        }

        &.-padding {
            padding-bottom: 80px;
        }
    }

    > .swapping-table-container {
        background-color: var(--main-grey);

        > .container {
            width: var(--main-width-full);
            margin: 0 auto;
            padding: 40px 0px 40px;

            > .title {
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                display: flex;
                align-items: center;

                > .-additional {
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--white);
                    display: flex;
                    align-items: center;
                    padding: 2px 10px 2px 3px;
                    margin-left: 14px;
                    background-color: var(--orange);
                    border-radius: 100px;
                    gap: 6px;
                }
            }

            .swap-calc {
                display: flex;
                align-items: center;
                column-gap: 10px;
                font-weight: 500;
                font-size: 28px;
                line-height: 144%;

                > .currency {
                    padding: 15px 30px;
                    background-color: var(--main-grey);
                    border-radius: 10px;
                }
            }
        }
    }
}

@media (max-width: 1279px) {
    .swap-page {
        > .swap-offer-block {
            > .container {
                > .description {
                    max-width: 430px;
                }

                > .warning-block {
                    width: 430px;
                }
            }
        }

        > .swapping-table-container {
            > .container {
                > .title {
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 48px;
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .swap-page {
        > .swap-offer-block {
            background-size: cover;
            background-position: 60%;

            > .container {
                > .title {
                    font-size: 36px;
                    line-height: 44px;
                }

                > .description {
                    max-width: 350px;
                    font-size: 14px;
                    line-height: 25px;
                }

                > .warning-block {
                    font-size: 15px;
                    line-height: 22px;
                    width: 350px;
                    column-gap: 5px;
                    padding: 15px 20px;

                    > .btn {
                        font-size: 13px;
                        line-height: 19px;
                        padding: 10px 20px;
                    }
                }
            }
        }

        > .swapping-table-container {
            > .container {
                > .title {
                    font-size: 24px;
                    line-height: 36px;

                    > .-additional {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .swap-page {
        padding-bottom: 15px;

        > .swap-offer-block {
            min-height: auto;
            background-position: -480px center;
            background-size: 2560px 100%;
            padding: 15px 15px 30px 15px;

            > .container {
                > .title {
                    font-size: 24px;
                    line-height: 32px;
                    text-align: center;
                }

                > .description {
                    margin-top: 10px;
                    max-width: 100%;
                    font-size: 12px;
                    line-height: 20px;
                    text-align: center;
                }

                > .warning-block {
                    width: 100%;
                    margin-top: 20px;
                    flex-direction: column;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    row-gap: 10px;
                    padding: 20px;

                    > .btn {
                        width: 100%;
                    }
                }
            }
        }

        > .content {
            background-color: var(--main-grey);
            padding: 15px;

            > .status-info-banner {
                margin-top: 15px;
                flex-direction: column;
                padding: 15px;

                > .text {
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                }

                > .btn {
                    margin-top: 10px;
                }
            }
        }

        > .swapping-table-container {
            > .container {
                padding: 0px 0px 15px 0px;

                > .title {
                    font-size: 20px;
                    line-height: 26px;
                    justify-content: center;

                    > .-additional {
                        display: none;
                    }
                }

                .swap-calc {
                    column-gap: 5px;
                    font-size: 14px;
                    line-height: 144%;

                    > .currency {
                        padding: 5px 10px;
                        border-radius: 5px;
                    }
                }
            }

        }
    }
}
</style>
